// src/components/Dropdown.tsx
import React, {
    useState,
    useRef,
    useEffect,
    KeyboardEvent,
    MouseEvent,
} from "react"

// Define the props for the Dropdown component
interface DropdownProps {
    options: string[]
    selected: string
    placeholder: string
    onSelect: (option: string) => void
}

// Dropdown Component
const Dropdown: React.FC<DropdownProps> = ({
    options,
    selected,
    onSelect,
    placeholder,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        document.addEventListener("touchstart", handleClickOutside) // For touch devices

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
            document.removeEventListener("touchstart", handleClickOutside)
        }
    }, [])

    // Handle keyboard navigation
    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            setIsOpen(false)
        }
    }

    // Handle option selection via keyboard
    const handleOptionKeyDown = (
        e: KeyboardEvent<HTMLLIElement>,
        option: string
    ) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault()
            onSelect(option)
            setIsOpen(false)
        }
    }

    return (
        <div
            style={{
                position: "relative",
                display: "inline-block",
                width: "200px",
            }}
            ref={dropdownRef}
            onKeyDown={handleKeyDown}
        >
            <button
                type="button"
                aria-haspopup="listbox"
                aria-expanded={isOpen}
                onClick={() => setIsOpen((prev) => !prev)}
                style={{
                    width: "100%",
                    padding: "10px 20px",
                    fontSize: "16px",
                    cursor: "pointer",
                    textAlign: "left",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            >
                {selected || placeholder}
                <span aria-hidden="true" style={{ float: "right" }}>
                    ▼
                </span>
            </button>
            {isOpen && (
                <ul
                    role="listbox"
                    aria-activedescendant={selected}
                    tabIndex={-1}
                    style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        right: 0,
                        marginTop: "5px",
                        padding: 0,
                        listStyle: "none",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                        maxHeight: "150px",
                        overflowY: "auto",
                        zIndex: 1000,
                    }}
                >
                    {options.map((option, index) => (
                        <li
                            key={index}
                            id={option}
                            role="option"
                            aria-selected={selected === option}
                            onClick={() => {
                                onSelect(option)
                                setIsOpen(false)
                            }}
                            onKeyDown={(e) => handleOptionKeyDown(e, option)}
                            tabIndex={0}
                            style={{
                                padding: "10px 20px",
                                cursor: "pointer",
                                backgroundColor:
                                    selected === option ? "#f0f0f0" : "#fff",
                            }}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default Dropdown
